/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function MembershipFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'How do I join?',
        answer: 'Simply enter your email under \'JOIN,\' verify it through the email we send, and you\'re all set! No passwords required—just use your email to log in, and we\'ll send a magic link to access your account instantly!',
      },
      {
        id: 2,
        title: 'Where can I track progress?',
        answer: 'You can track your progress by logging into your [Visionary Club]  account and going to your benefits page. When you level up to a new tier, you will see all the new perks on your benefits page!',
      },
      {
        id: 3,
        title: 'How is my membership status determined?',
        answer: 'Your Visionary Club tier is based on the points you collect over the year, with many ways to earn beyond just shopping!',
      },
      {
        id: 4,
        title: 'How do I earn?',
        answer: 'Earn 1 point for every $1 spent on a purchase.',
      },
      {
        id: 5,
        title: 'Do tiers/points expire or reset?',
        answer: 'Yes, your tier level is determined by your spending from the previous year. To maintain or move up a tier, you need to meet the required spend. Points will expire after one year of no activity, such as no purchases or point redemptions. Redeeming points won’t affect your tier status.',
      },
      {
        id: 6,
        title: 'What do I get on my Birthday?',
        answer: 'Every tier lets you add your birthday anytime, and when your special day arrives, you’ll receive 100 birthday points! A gift just for you!',
      },
      {
        id: 7,
        title: 'Leveling up to the next Tier: How it works',
        answer: 'You’ll advance to the next level as soon as you reach the maximum points for your current tier. There are plenty of ways to earn points beyond shopping—sharing on social media or referring friends can help you rack up points too!',
      },
      {
        id: 8,
        title: 'What are other ways to earn',
        answer: 'Excellent question! As a part of the Visionary Club, we want to reward you for spreading the word. You’ll earn points every time you share your unique link with friends and family or refer someone to join the Club. Plus, more earning options are coming soon—like writing a review, joining our mailing list, or tagging and sharing us on Instagram. Stay tuned for updates!',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default MembershipFAQ;
